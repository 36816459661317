import { mixPanelEvent } from "@features/activities/data";
import { getCurrentLocaleCode } from "@taager/localization";
import { version } from "package.json";

export enum ProductsActivityEvents {
  REQUEST_PRODUCT_SUBMIT = "request_product_submit",
}

export function trackRequestProduct({
  category,
  productDetails,
}: {
  category: string;
  productDetails: string;
}) {
  const payload = {
    Language: getCurrentLocaleCode(),
    "Interface Version": version,
    "Category Name": category,
    Description: productDetails,
  };

  mixPanelEvent(ProductsActivityEvents.REQUEST_PRODUCT_SUBMIT, payload);
}
