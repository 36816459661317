import { BaseRepository } from "@features/shared/data";
import { MerchantRepositoryContract, ShoppingSummary } from "../../domain";
import { userApiUrl } from "../constants";

export class MerchantRepository
  extends BaseRepository
  implements MerchantRepositoryContract
{
  public async getShoppingSummary(): Promise<ShoppingSummary> {
    // Getting 403 Forbidden
    const response = await this.http.get<ShoppingSummary>(
      userApiUrl.merchantShoppingSummary,
    );

    return response.data;
  }
}
