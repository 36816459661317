import { PlainLocalStorageDriver, setCacheConfigurations } from "@taager/cache";
import {
  decrypt,
  encrypt,
  setEncryptionConfigurations,
} from "@taager/encryption";
import { AES } from "crypto-js";

// set encryption configurations
setEncryptionConfigurations({
  key: "Taager-2025",
  driver: AES,
});

// the usage for the cache driver will be by the cache manager not the driver.
setCacheConfigurations({
  // the driver will be set based on the environment
  // if we are in production we will use the EncryptedLocalStorageDriver, which will encrypt the data in the local storage
  // if we are in development we will use the PlainLocalStorageDriver, which will store the data as it is in the local storage
  driver: new PlainLocalStorageDriver(),
  // this will prefix any key we set in the cache
  prefix: "tgr--", // Taager
  // the encryption configurations MUST be defined if we are going to use any `Encrypted` driver
  encryption: {
    encrypt,
    decrypt,
  },
});
