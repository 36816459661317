export function generateEasyOrdersExpireTime(): string {
  const currentDate = new Date();
  const expiryDate = new Date(currentDate).setFullYear(
    currentDate.getFullYear() + 1,
  );
  const formattedExpiryDate = new Date(expiryDate).toISOString().slice(0, 10);

  return formattedExpiryDate;
}

export function isEasyOrderApiKeyInActive(data: any) {
  if (!data) return true;

  return data.length === 0 || !data[0].isActive || data[0].isExpired;
}
