import type {
  CountryCode,
  StandardCountryCode,
} from "@features/country/domain";

export const mappedCountryCodes: Record<StandardCountryCode, CountryCode> = {
  KSA: "SAU",
  EGY: "EGY",
  UAE: "ARE",
  IRQ: "IRQ",
  Test: "TNT",
  OMN: "OMN",
  KWT: "KWT",
  SAU: "SAU",
};

export const KSA = mappedCountryCodes.KSA;
export const EGY = mappedCountryCodes.EGY;
export const UAE = mappedCountryCodes.UAE;
export const IRQ = mappedCountryCodes.IRQ;
export const OMN = mappedCountryCodes.OMN;
export const KWT = mappedCountryCodes.KWT;
