import { type UserDevice } from "@features/user/data";

export function getUserDevice(): UserDevice {
  const screenWidth = window.innerWidth;

  if (screenWidth < 768) {
    return "mobile";
  }

  if (screenWidth >= 768 && screenWidth < 1024) {
    return "tablet";
  }

  return "desktop";
}
