import { parseError } from "@features/shared/data";
import { createAtom } from "@taager/atom";
import { toast } from "sonner";

export type ToastAtom = {
  type: "success" | "error" | "info";
  message: string;
  title?: string;
};

export const toastAtom = createAtom<ToastAtom>({
  key: "toast",
  default: {},
});

/**
 * Show a success toast message
 */
export function toastSuccess(message: string): void {
  toast(message, {
    duration: 5000,
    className:
      "bg-toast-primary-success text-white rounded-md p-4 flex items-center",
  });
}

/**
 * Show an error toast message
 */
export function toastError(message: string): void {
  toast(message, {
    duration: 5000,
    className:
      "bg-toast-primary-error text-white rounded-md p-4 flex items-center",
  });
}

/**
 * Show an info toast message
 */
export function toastInfo(message: string): void {
  toast(message, {
    duration: 5000,
    className:
      "bg-toast-primary-info text-white rounded-md p-4 flex items-center",
  });
}

/**
 * Show a warning toast message
 */
export function toastWarning(message: string): void {
  toast(message, {
    duration: 5000,
    className:
      "bg-toast-primary-danger text-white rounded-md p-4 flex items-center",
  });
}

/**
 * Parse and show error message in toast
 */
export function catchError(error: any): void {
  toastError(parseError(error));
}
