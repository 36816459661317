import { BaseRepository, CACHE_FOR } from "@features/shared/data";
import cache from "@taager/cache";
import { type GenericObject } from "@taager/reinforcements";
import { environment } from "environment";
import { initializeApp } from "firebase/app";
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from "firebase/remote-config";
import { type FeaturesListRepositoryContract } from "../../domain";
import { FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY } from "../constants";
import { mapFirebaseRemoteConfigFeatures } from "../mappers";

export class FirebaseRemoteConfigRepository
  extends BaseRepository
  implements FeaturesListRepositoryContract
{
  public async list(): Promise<GenericObject> {
    const cachedFeatures: string[] = cache.get(
      FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY,
    );

    if (cachedFeatures) {
      return cachedFeatures;
    }

    // initialize Firebase
    const app = await initializeApp(environment.firebase);

    const remoteConfig = getRemoteConfig(app);

    await fetchAndActivate(remoteConfig);

    // now we can use remoteConfig.getBoolean('feature_name') to check if a feature is enabled
    const featuresList = getAll(remoteConfig);

    const features = mapFirebaseRemoteConfigFeatures(featuresList);

    cache.set(
      FIREBASE_REMOTE_CONFIG_FEATURES_CACHE_KEY,
      features,
      CACHE_FOR.TWELVE_HOURS,
    );

    return features;
  }
}
