import {
  CACHE_VERSION,
  CACHE_VERSION_KEY,
} from "@features/drivers/data/constants";

export function getCurrentCacheVersion(): string {
  return localStorage.getItem(CACHE_VERSION_KEY) || "";
}

export function setCurrentCacheVersion(): void {
  localStorage.setItem(CACHE_VERSION_KEY, CACHE_VERSION);
}

function restoreLocalStorageAndAddCacheVersion(): void {
  // If the cache version is not set or outdated, clear the entire local storage
  localStorage.clear();

  // Set the new cache version in local storage
  localStorage.setItem(CACHE_VERSION_KEY, CACHE_VERSION);

  // Reload the page to apply changes
  window.location.reload();
}

// why we are adding it here and not on data layer?
// because we need to make a window reload, this is a presentation layer concern
export function checkLocalStorageVersion(): void {
  // Check if the local storage contains the current cache version
  const storageVersion = localStorage.getItem(CACHE_VERSION_KEY);

  if (!storageVersion) {
    setCurrentCacheVersion();
  } else if (storageVersion !== CACHE_VERSION) {
    restoreLocalStorageAndAddCacheVersion();
  }
}
