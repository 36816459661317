import { NotificationsRepositoryContract } from "@features/notifications/domain";
import { BaseRepository } from "@features/shared/data";
import { NOTIFICATIONS_API_URLS } from "../constants";
import {
  NotificationItemModel,
  NotificationListModel,
  NotificationReadModel,
} from "../types";

export class NotificationsRepository
  extends BaseRepository
  implements NotificationsRepositoryContract
{
  async list(): Promise<NotificationItemModel[]> {
    const data = await this.http.get<NotificationListModel>(
      NOTIFICATIONS_API_URLS.USER_NOTIFICATIONS,
    );

    return data.data.data.results;
  }

  async markAllAsSeen(params: NotificationReadModel): Promise<void> {
    await this.http.patch<NotificationReadModel, void>(
      NOTIFICATIONS_API_URLS.USER_NOTIFICATIONS,
      params,
    );
  }

  async markAsInteracted(params: {
    notificationId: string;
    data: NotificationReadModel;
  }): Promise<void> {
    this.http.patch<NotificationReadModel, void>(
      `${NOTIFICATIONS_API_URLS.USER_NOTIFICATIONS}/${params.notificationId}`,
      params.data,
    );
  }
}
