import { gTagEvent } from "@features/activities/data/google-tag-manager";
import { mixPanelEvent } from "@features/activities/data/mixpanel";
import { GenericObject } from "@taager/reinforcements";

export const BROADCAST_TO_ALL_CHANNELS = true;

export function trackEvent(
  eventName: string,
  data?: GenericObject,
  allChannels = false,
): void {
  mixPanelEvent(eventName, data);

  if (allChannels) {
    gTagEvent(eventName, data);
  }
}

export function trackEventCallback(
  eventName: string,
  data?: GenericObject,
): () => void {
  return () => {
    trackEvent(eventName, data);
  };
}

export function trackEventError(
  eventName: string,
  error: any,
  errorKey = "err",
) {
  trackEvent(eventName, { [errorKey]: error.message });
}
