import { http } from "../../drivers/data";
import "./locales";
import { user } from "./services";

http.events.beforeSending(options => {
  options.headers["ui-session-key"] = user.uiSessionKey;

  if (!user.isLoggedIn) return;

  options.headers.Authorization = `Bearer ${user.accessToken}`;
  options.headers.taagerId = user.id;
});
