import { trackRequestProduct } from "@features/products/activities";
import {
  CreateProductRequestData,
  ProductsRepositoryContract,
} from "@features/products/domain";
import { BaseRepository } from "@features/shared/data";
import { productsApiUrls } from "../constants";

export class ProductsRepository
  extends BaseRepository
  implements ProductsRepositoryContract
{
  /**
   * Post a request for a new product
   */
  public async createProductRequest(
    data: CreateProductRequestData,
  ): Promise<void> {
    await this.http.post<CreateProductRequestData, void>(
      productsApiUrls.REQUEST_PRODUCT_URL,
      data,
    );

    trackRequestProduct(data);
  }
}
