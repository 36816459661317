import { country } from "@features/country/data";
import { BaseRepository, promiseAllObject } from "@features/shared/data";
import {
  DukanData,
  EasyOrdersApiKey,
  Store,
  StoreProvider,
  type StoresRepositoryContract,
} from "@features/stores/domain";
import { storesApiUrl } from "../constants";
import { mapEasyOrderStore, mapStoresList, mapUserStores } from "../mappers";
import { UserStoreModel } from "../models";
import { storesList } from "../utils/all-stores.data";
import {
  generateEasyOrdersExpireTime,
  isEasyOrderApiKeyInActive,
} from "../utils/easy-orders.utils";

export class StoresRepository
  extends BaseRepository
  implements StoresRepositoryContract
{
  /**
   * Return all tores list
   */
  public async list(): Promise<Store[]> {
    const result = await promiseAllObject({
      easyOrders: this.getEasyOrderStore(),
      dukan: this.getDukanStore(),
      userStores: this.getUserStores(),
      allStores: this.getAllStores(),
    });

    return mapStoresList(result);
  }

  /**
   * Connect the given store
   */
  public async connect(_store: Store): Promise<void> {}

  /**
   * Disconnect the given store
   */
  public async disconnect(_store: Store): Promise<void> {}

  /**
   * Get Dukan Store
   */
  protected async getDukanStore(): Promise<Store<DukanData> | void> {
    if (
      !storesList[StoreProvider.Dukan].supportedCountries.includes(country.code)
    )
      return;

    try {
      const { data } = await this.http.get<DukanData>(
        storesApiUrl.getDukanStore,
      );

      return {
        ...storesList[StoreProvider.Dukan],
        isConnected: true,
        data,
      };
    } catch (error: any) {
      if (error.response.status === 404) {
        return {
          ...storesList[StoreProvider.Dukan],
          isConnected: false,
        } as Store<DukanData>;
      }

      return;
    }
  }

  /**
   * Get Easy Order Store
   */
  protected async getEasyOrderStore(): Promise<Store<EasyOrdersApiKey> | void> {
    if (
      !storesList[StoreProvider.EasyOrders].supportedCountries.includes(
        country.code,
      )
    )
      return;

    const { data } = await this.http.get<EasyOrdersApiKey[]>(
      storesApiUrl.getEasyOrdersApiKey,
    );

    if (isEasyOrderApiKeyInActive(data)) {
      // generate new key

      const payload = {
        expiresAt: generateEasyOrdersExpireTime(),
      };

      const { data: newKey } = await this.http.post<
        typeof payload,
        EasyOrdersApiKey
      >(storesApiUrl.generateEasyOrdersApiKey, payload);

      return mapEasyOrderStore(newKey);
    }

    return mapEasyOrderStore(data[0]);
  }

  /**
   * Get user stores
   */
  protected async getUserStores(): Promise<Store[]> {
    const { data } = await this.http.get<UserStoreModel[]>(
      storesApiUrl.userStores,
    );

    return mapUserStores(data);
  }

  /**
   * Get all stores list
   */
  protected async getAllStores(): Promise<Store[]> {
    return [
      storesList[StoreProvider.YouCan],
      storesList[StoreProvider.Zid],
      storesList[StoreProvider.WooCommerce],
    ];
  }
}
