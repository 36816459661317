import { country } from "@features/country/data/index.js";
import { localeCodesList } from "@features/drivers/data";
import { LOCALE_CODE_CACHE_KEY } from "@features/drivers/data/constants/index.js";
import {
  defaultLocaleCode,
  fallbackLocaleCode,
  getCountryCodeFromUrl,
} from "@features/shared/presentation";
import cache from "@taager/cache";
import {
  getCurrentLocaleCode,
  setCurrentLocaleCode,
} from "@taager/localization";
import { ApplicationConfigurations, setAppConfigurations } from "@taager/react";
import { setHelmetConfigurations } from "@taager/react-helmet";
import { jsxConverter } from "@taager/react-localization";
import {
  changeLocaleCode,
  queryString,
  routerEvents,
  setRouterConfigurations,
} from "@taager/react-router";
import "./definitions.d.ts";
import { routerConfigurations } from "./router-configurations";

// @taager/react-helmet configurations
setHelmetConfigurations({
  appendAppName: true,
  appNameSeparator: " | ",
  translatable: true,
  translateAppName: true,
});

const localeCodeInCache = cache.get(LOCALE_CODE_CACHE_KEY);

// fix the locale code in cache if it's not ar or en, which will be mostly a country code
if (localeCodeInCache && !["ar", "en"].includes(localeCodeInCache)) {
  cache.set(LOCALE_CODE_CACHE_KEY, "ar");
}

// keep this for the app handling, just change the values
const appConfigurations: ApplicationConfigurations = {
  localization: {
    defaultLocaleCode:
      queryString.get("locale") || cache.get("loc", defaultLocaleCode),
    fallback: fallbackLocaleCode,
    locales: localeCodesList,
    converter: jsxConverter,
    get localCode() {
      if (getCurrentLocaleCode() === "en") return "en";

      return country.shortCode || getCountryCodeFromUrl() || "ar";
    },
  },
};

// router configurations
setRouterConfigurations(routerConfigurations);

setAppConfigurations(appConfigurations);

routerEvents.onLocaleChanged(localeCode => {
  cache.set("loc", localeCode);
});

// because this file is before the app starts (@see src/main.tsx), we need to manually update the current locale code as well
// another way to change locale code from query string
if (queryString.get("locale")) {
  changeLocaleCode(queryString.get("locale"));
  setCurrentLocaleCode(queryString.get("locale"));
}
