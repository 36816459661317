import { RedirectMobileAppEvents } from "@features/user/data/activities/redirect-mobile-app.activities";
import { UserLoginActivityEvents } from "./login.activities";
import { UserSignUpActivityEvents } from "./sign-up.activities";

export * from "./login.activities";
export * from "./redirect-mobile-app.activities";
export * from "./sign-up.activities";

export const UserActivityEvents = {
  ...UserSignUpActivityEvents,
  ...UserLoginActivityEvents,
  ...RedirectMobileAppEvents,
} as const;
