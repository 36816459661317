import { countriesList, country } from "@features/country/data";
import { user } from "@features/user/data";

function getCountryCodeFromUrl(): string {
  const segments = window.location.pathname.split("/");
  return (segments?.[1] || "").toLowerCase();
}

/**
 * Check if the url country code (iso code 2) is different from the current country code (iso 2).
 */
export function countryIsDifferentFromUrlCountryCode(): boolean {
  return country.shortCode !== getCountryCodeFromUrl();
}

export function updateCountryToUrlCountryCode(): void {
  const countryIsoCode2: string = getCountryCodeFromUrl();

  if (country.shortCode !== countryIsoCode2 && user.isLoggedIn) {
    const newCountry = countriesList.getByIso2(countryIsoCode2);

    if (newCountry) {
      country.set(newCountry);
    }
  }
}
