import { GenericObject } from "@taager/reinforcements";

declare let gtag: (
  command: string,
  eventName: string,
  payload?: GenericObject,
) => void;

export function gTagEvent(eventName: string, payload?: GenericObject): void {
  gtag("event", eventName, payload);
}
