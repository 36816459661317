import { type GenericObject } from "@taager/reinforcements";
import type { AxiosResponse } from "axios";

export class HttpError extends Error {
  /**
   * Status code
   */
  public status: number;

  /**
   * Status text
   */
  public statusText: string;

  /**
   * Response headers
   */
  public headers: GenericObject;

  /**
   * Response data
   */
  public data: unknown;

  /**
   * Constructor
   *
   * @param message Error Message
   * @param response Axios Response
   */
  public constructor(
    message: string,
    public response: AxiosResponse,
  ) {
    super(message);
    this.status = response.status;
    this.statusText = response.statusText;
    this.headers = response.headers as GenericObject;
    this.data = response.data;
  }
}
