import { country } from "@features/country/data";
import { isFirebaseFeatureEnabledSync } from "@features/feature-manager/data";

export function isSignupV3Enabled(): boolean {
  return isFirebaseFeatureEnabledSync("IS_ENABLED_SIGNUP_V3");
}

export function isUserReferralEnabled(): boolean {
  return country.is("KSA");
}
