// CACHE_KEYS

export enum CACHE_FOR {
  ONE_HOUR = 60 * 60,
  TWO_HOURS = 60 * 60 * 2,
  THREE_HOURS = 60 * 60 * 3,
  FOUR_HOURS = 60 * 60 * 4,
  FIVE_HOURS = 60 * 60 * 5,
  SIX_HOURS = 60 * 60 * 6,
  SEVEN_HOURS = 60 * 60 * 7,
  EIGHT_HOURS = 60 * 60 * 8,
  NINE_HOURS = 60 * 60 * 9,
  TEN_HOURS = 60 * 60 * 10,
  ELEVEN_HOURS = 60 * 60 * 11,
  TWELVE_HOURS = 60 * 60 * 12,
  ONE_DAY = 60 * 60 * 24,
}
