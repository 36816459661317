/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-async-promise-executor */
import events, { EventSubscription } from "@taager/events";
import { merge, Random } from "@taager/reinforcements";
import { isFormElement, isPlainObject } from "@taager/supportive-is";
import axios, {
  Axios,
  AxiosRequestConfig,
  AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import {
  EndpointConfigurations,
  EndpointEvent,
  RequestEndpointConfigurations,
} from "./Endpoint.types";

export class Endpoint extends Axios {
  /**
   * Last request controller
   */
  public lastRequest?: AbortController;

  /**
   * Default configurations
   */
  protected defaultConfigurations: EndpointConfigurations = {
    cache: false,
    cacheOptions: {
      ttl: 5 * 60, // 5 minutes
    },
    ...(axios.defaults as any),
  };

  /**
   * Endpoint id
   */
  protected endpointId = Random.id();

  /**
   * Endpoint event namespace
   */
  protected eventNamespace = `endpoint.${this.endpointId}`;

  /**
   * Constructor
   */
  public constructor(public configurations: EndpointConfigurations = {}) {
    super(configurations);
    this.defaults = merge(this.defaultConfigurations, configurations) as any;
    this.configurations = merge(this.defaultConfigurations, configurations);

    this.boot();
  }

  /**
   * Set endpoint configurations
   */
  public setConfigurations(configurations: EndpointConfigurations) {
    this.configurations = merge(this.configurations, configurations);

    this.defaults = this.configurations as any;
  }

  /**
   * Boot the endpoint
   */
  protected boot() {
    this.addInterceptors();
  }

  /**
   * Add axios interceptors
   */
  protected addInterceptors(): void {
    this.addRequestInterceptors();
    this.addResponseInterceptors();
  }

  /**
   * Add request interceptors
   */
  protected addRequestInterceptors(): void {
    this.interceptors.request.use(
      (requestConfig: InternalAxiosRequestConfig) => {
        // this will allow us to upload images
        const headers = requestConfig.headers || {};

        let data: any = requestConfig.data;

        if (isFormElement(data)) {
          data = new FormData(data);
        }

        if (isPlainObject(data)) {
          headers!["Content-Type"] = "Application/json";

          data = JSON.stringify(data);
        }

        requestConfig.data = data;

        const authHeader = this.configurations.setAuthorizationHeader;

        if (authHeader && !headers?.Authorization) {
          if (typeof authHeader === "function") {
            const authorizationValue = authHeader(requestConfig);

            if (authorizationValue) {
              headers.Authorization = authorizationValue;
            }
          } else {
            headers.Authorization = authHeader;
          }
        }

        requestConfig.headers = headers;

        if (!requestConfig.signal) {
          this.lastRequest = new AbortController();

          requestConfig.signal = this.lastRequest.signal;
        }

        // trigger event of sending ajax request
        this.trigger("sending", requestConfig);

        return requestConfig;
      },
    );
  }

  /**
   * Add response interceptors
   */
  protected addResponseInterceptors(): void {
    this.interceptors.response.use(
      response => {
        if (response.config.signal === this.lastRequest?.signal) {
          this.lastRequest = undefined;
        }

        this.trigger("complete", response);
        this.trigger("success", response);

        return response;
      },
      error => {
        if (error.response?.config?.signal === this.lastRequest?.signal) {
          this.lastRequest = undefined;
        }

        this.trigger("complete", error.response);
        this.trigger("error", error.response);

        return Promise.reject(error);
      },
    );
  }

  /**
   * {@inheritDoc}
   */
  public get<T = any, R = AxiosResponse<T>>(
    url: string,
    options?: RequestEndpointConfigurations,
  ): Promise<R> {
    const request: Promise<R> = new Promise(async (resolve, reject) => {
      const isCacheable =
        options?.cache ||
        (this.configurations.cache && options?.cache !== false);

      if (isCacheable) {
        const cacheConfigurations = {
          ...(this.configurations.cacheOptions || {}),
          ...(options?.cacheOptions || {}),
        };

        const cacheDriver = cacheConfigurations.driver;

        if (!cacheConfigurations.key) {
          cacheConfigurations.key =
            this.configurations.cacheOptions?.generateKey?.(
              url,
              options?.params,
            ) || this.getCacheKey(url, options?.params);
        }

        const cacheKey = cacheConfigurations.key;

        const response = await cacheDriver?.get(cacheKey);

        if (response) {
          resolve(response as any);
        } else {
          super
            .get(url, options)
            .then((response: AxiosResponse<T>) => {
              if (isCacheable && cacheDriver) {
                cacheDriver.set(
                  cacheKey,
                  {
                    data: response.data,
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                  },
                  cacheConfigurations.ttl ?? cacheConfigurations.expiresAfter,
                );
              }
              resolve(response as any);
            })
            .catch(error => reject(error));
        }
      } else {
        super
          .get(url, options)
          .then(resolve as any)
          .catch(reject);
      }
    });

    return request;
  }

  /**
   * Get endpoint last request
   */
  public getLastRequest(): AbortController | undefined {
    return this.lastRequest;
  }

  /**
   * Get events subscribers
   */
  public get events() {
    return {
      /**
       * Triggered when response is returned with error response
       */
      onError: (
        callback: (response: AxiosResponse) => void,
      ): EventSubscription => {
        return events.subscribe(`${this.eventNamespace}.error`, callback);
      },
      /**
       * Triggered when response is returned with success response
       */
      onSuccess: (
        callback: (response: AxiosResponse) => void,
      ): EventSubscription => {
        return events.subscribe(`${this.eventNamespace}.success`, callback);
      },
      /**
       * Triggered when response is returned wether it is success or error response
       */
      onComplete: (
        callback: (response: AxiosResponse) => void,
      ): EventSubscription => {
        return events.subscribe(`${this.eventNamespace}.complete`, callback);
      },
      /**
       * Triggered before sending response
       */
      beforeSending: (
        callback: (config: AxiosRequestConfig) => void,
      ): EventSubscription => {
        return events.subscribe(`${this.eventNamespace}.sending`, callback);
      },
    };
  }

  /**
   * Trigger the given event
   */
  protected trigger(event: EndpointEvent, ...args: any[]): any {
    return events.trigger(`${this.eventNamespace}.${event}`, ...args);
  }

  /**
   * Get cache key form the given path
   */
  public getCacheKey(path: string, params?: any): string {
    const key = `endpoint.${this.configurations.baseURL}.${path}`;

    if (params) {
      return `${key}.${JSON.stringify(params)}`;
    }

    return key;
  }

  /**
   * Get configurations list
   */
  public getConfigurations(): EndpointConfigurations {
    return this.configurations;
  }

  /**
   * Get config for the given key
   */
  public getConfig(key: keyof EndpointConfigurations, defaultValue?: any): any {
    return this.configurations[key] ?? defaultValue;
  }
}
