// import { hazelnut } from "@mentoor.io/hazelnut-client";
// import packageInfo from "package.json";
import user from "user";
import { mixpanelService } from "./mixpanel";

mixpanelService.init();

if (user.isLoggedIn) {
  mixpanelService.identify();
}

// hazelnut.init({
//   apiKey: "kr9WyyxwNQ0Y0IycIhpT4oWgLyDPcADdhqeTrIbvFMPax1Duf7TNwdjzR7UzGAyv",
//   captureUncaughtErrors: true,
//   environment: process.env.NODE_ENV as "development" | "production",
//   version: packageInfo.version,
//   get user() {
//     if (!user.isLoggedIn) {
//       return {
//         uiSessionId: user.uiSessionKey,
//       };
//     }

//     return {
//       id: user.id,
//       uiSessionId: user.uiSessionKey,
//     };
//   },
// });
