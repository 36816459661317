import { BaseRepository } from "@features/shared/data";
import type { CountriesRepositoryContract, Country } from "../../domain";
import { COUNTRIES_CACHE_KEY, countriesApiUrl } from "../constants";
import { mapCountry } from "../mappers/country-mapper";
import { CountryModel } from "../models/country.model";

export class CountriesRepository
  extends BaseRepository
  implements CountriesRepositoryContract
{
  /**
   * Get a list of countries
   */
  public async list(): Promise<Country[]> {
    return this.cacheable(COUNTRIES_CACHE_KEY, async () => {
      const response = await this.http.get<{
        data: CountryModel[];
      }>(countriesApiUrl.list);

      const countries = response.data.data.map(mapCountry);

      return countries;
    });
  }

  public async updateSelectedMarket(iso3: string): Promise<void> {
    await this.http.patch(countriesApiUrl.updateMarket, {
      selectedMarket: iso3,
    });
  }
}
