import { country } from "@features/country/data/services/country.service";
import { user } from "@features/user/data/services/user";
import { environment } from "environment";
import mixpanel from "mixpanel-browser";
import packageInfo from "package.json";

export class MixpanelManagerService {
  public init(): void {
    if (environment.MIXPANEL_PROXY_DOMAIN) {
      mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN, {
        api_host: environment.MIXPANEL_PROXY_DOMAIN,
      });
    } else {
      mixpanel.init(environment.MIXPANEL_PROJECT_TOKEN);
    }
  }

  public identify(): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      return;
    }

    const merchantId: string = user?.id?.toString();

    mixpanel.identify(merchantId);

    this.setUpUser(merchantId, user.data);
    this.setUpSuperProperties(user);
  }

  private setUpUser(merchantId: string, userData: any = {}): void {
    const userObject = {
      $tager_ID: merchantId,
      $phone: userData?.phoneNum,
      $email: userData?.email,
      $name:
        userData?.fullName || `${userData?.firstName} ${userData?.lastName}`,
      join_date: userData?.createdAt,
    };

    mixpanel.people.set(userObject);
  }

  private setUpSuperProperties(userData: any = {}) {
    const superProperties: any = {
      $email: userData?.email,
      $version: packageInfo.version,
      $ui_session_key: user.uiSessionKey,
      $selected_country: country.code,
    };
    mixpanel.register(superProperties);
  }

  public track(id: string, action: any = {}): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      return;
    }

    // Only users who are logged in should be tracked
    // a Logged in user is either a user who has logged in or signed up (after first phase)
    if (user.isLoggedIn) {
      mixpanel.track(id, action);
    }
  }

  public reset(): void {
    if (!this.mixpanelCanBeUsedOnUserDevice()) {
      return;
    }
    mixpanel.reset();
  }

  private mixpanelCanBeUsedOnUserDevice() {
    let verdict = false;
    try {
      mixpanel?.get_distinct_id();
      const hasNotOptedOutTracking = !mixpanel.has_opted_out_tracking();
      verdict = true && hasNotOptedOutTracking;
    } catch {
      // Do nothing
    }

    return verdict;
  }
}

export const mixpanelService = new MixpanelManagerService();
