const instances: Record<string, any> = {};

const defaultTTl = 5 * 60 * 1000; // 5 minutes

/**
 * Singleton cleanup
 */
function singletonCleanup(): void {
  for (const [key, value] of Object.entries(instances)) {
    if (value.expiresAt < Date.now()) {
      delete instances[key];
    }
  }
}

setInterval(singletonCleanup, 1000);

/**
 * Singleton pattern for DI
 */
export function singleton<T>(
  _key: string,
  resolver: () => T,
  _ttl = defaultTTl,
): T {
  return resolver();
  // const cachedValue = instances[key];
  // if (cachedValue) {
  //   // we need to reset the `expiresAt` time
  //   cachedValue.expiresAt = Date.now() + ttl;
  //   return cachedValue.instance;
  // }

  // instances[key] = {
  //   instance: resolver(),
  //   expiresAt: Date.now() + ttl,
  // };

  // return instances[key].instance;
}
