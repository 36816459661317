/* eslint-disable no-await-in-loop */
import { FeatureManagerContract, FeatureProviderContract } from "../../domain";

export class FeatureManager implements FeatureManagerContract {
  /**
   * Providers list
   */
  public providers: FeatureProviderContract[] = [];

  /**
   * Initialize the feature manager
   */
  public async init(): Promise<void> {
    for (const provider of this.providers) {
      provider.init();
    }
  }

  /**
   * Register a feature provider
   */
  public registerProvider(provider: FeatureProviderContract): void {
    this.providers.push(provider);
  }

  /**
   * Determine if given feature is enabled
   */
  public async isEnabled(featureName: string): Promise<boolean> {
    for (const provider of this.providers) {
      if (await provider.isEnabled(featureName)) {
        return true;
      }
    }

    return false;
  }

  public async getValue<T>(featureName: string): Promise<T> {
    for (const provider of this.providers) {
      const value = await provider.getValue<T>(featureName);
      if (value) {
        return value;
      }
    }

    return undefined as any;
  }
}

export const featureManager = new FeatureManager();
