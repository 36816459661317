import { Category } from "@features/catalog/domain/entities";
import { CommercialCategoryRepositoryContract } from "@features/catalog/domain/types/commercial-categories-repository.contract";
import { country } from "@features/country/data";
import { BaseRepository } from "@features/shared/data";
import { CommercialCategoriesApiUrls } from "../constants/commercial-categories-api-urls";
import { mapCommercialCategory } from "../mappers/commercial-category.mapper";
import { CommercialCategoryEntity } from "../models/commercial-category.model";

export class CommercialCategoryRepository
  extends BaseRepository
  implements CommercialCategoryRepositoryContract
{
  public async list(countryCode = country.code): Promise<Category[]> {
    const response = await this.http.get<CommercialCategoryEntity[]>(
      CommercialCategoriesApiUrls.GET_COMMERCIAL_CATEGORIES_URL,
      {
        params: { countryCode },
      },
    );

    return response.data.map(mapCommercialCategory);
  }
}
