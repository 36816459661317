/* eslint-disable @typescript-eslint/no-shadow */
import { resolveCountriesRepository } from "@di/app";
import { user } from "@features/user/data";
import cache from "@taager/cache";
import { type Country } from "../../domain";
import { COUNTRIES_CACHE_KEY } from "../constants";
import { country } from "./country.service";

class CountriesList {
  /**
   * List of current user countries
   */
  protected countriesList: Country[] = cache.get(COUNTRIES_CACHE_KEY) || [];

  /**
   * whether the countries list is loaded or not
   */
  public isLoaded = !!this.countriesList.length;

  /**
   * Load countries
   */
  public async load(): Promise<Country[]> {
    // if (!user.isDataVerified && !user.isLegacyUser) return [];

    this.countriesList = await resolveCountriesRepository().list();

    this.isLoaded = true;

    if (user.selectedMarket) {
      country.set(this.get(user.selectedMarket)!);
    } else {
      // default will be SA
      country.set(
        this.countriesList.find(country => country.isoCode3 === "SA") ||
          this.countriesList?.[0],
      );
    }

    return this.countriesList;
  }

  /**
   * Get country data by its iso3 code
   */
  public get(iso3: string): Country | undefined {
    return this.countriesList.find(country => country.isoCode3 === iso3);
  }

  /**
   * Get country by iso code 2
   */
  public getByIso2(iso2: string): Country | undefined {
    iso2 = iso2.toUpperCase();

    return this.countriesList.find(
      country => country.isoCode2.toUpperCase() === iso2,
    );
  }

  /**
   * Get countries list
   *
   * This property MUST be called only while user is logged in
   */
  public get list(): Country[] {
    return this.countriesList;
  }
}

export const countriesList = new CountriesList();
