import { GenericObject } from "@taager/reinforcements";
import { Value } from "firebase/remote-config";

function addFeatureAsBoolean(
  value: Value,
  key: string,
  features: GenericObject,
): boolean {
  if (value.asBoolean()) {
    features[key] = true;
    return true;
  }

  return false;
}

function addFeatureAsNumber(
  value: Value,
  key: string,
  features: GenericObject,
): boolean {
  const number = value.asNumber();

  if (number) {
    features[key] = number;
    return true;
  }

  return false;
}

function addFeatureAsObject(
  value: Value,
  key: string,
  features: GenericObject,
): boolean {
  try {
    features[key] = JSON.parse(value.asString());
    return true;
  } catch {
    return false;
  }
}

function addFeatureAsString(
  value: Value,
  key: string,
  features: GenericObject,
): boolean {
  const stringValue = value.asString();
  if (stringValue) {
    features[key] = stringValue;
    return true;
  }
  return false;
}

export function mapFirebaseRemoteConfigFeatures(
  featuresList: GenericObject<Value>,
): GenericObject {
  const features: GenericObject = {};
  const featureAdders = [
    addFeatureAsBoolean,
    addFeatureAsNumber,
    addFeatureAsObject,
    addFeatureAsString,
  ];

  for (const [key, value] of Object.entries(featuresList)) {
    for (const addFeature of featureAdders) {
      if (addFeature(value, key, features)) {
        break;
      }
    }
  }

  return features;
}
