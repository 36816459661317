import { EGY, IRQ, KSA, OMN, UAE } from "@features/country/data";
import { Store, StoreProvider } from "@features/stores/domain";

const easyOrdersStore: Store = {
  name: "stores.easyOrders",
  icon: "https://storage.googleapis.com/taager-public-media/learning/easy-orders.svg",
  provider: StoreProvider.EasyOrders,
  redirectRoute: "",
  howToConnectVideoUrl:
    "https://www.youtube.com/embed/Sc8xaCReSYI?si=Ot9NTLCkUiupYmIr",
  supportedCountries: [EGY, KSA, IRQ, UAE, OMN],
};

const dukanStore: Store = {
  name: "stores.dukan",
  icon: "https://storage.googleapis.com/taager-public-media/learning/dukan.svg",
  provider: StoreProvider.Dukan,
  redirectRoute: "",
  supportedCountries: [EGY, UAE],
};

const youCanStore: Store = {
  name: "stores.youCan",
  icon: "https://storage.googleapis.com/taager-public-media/learning/you-can.svg",
  provider: StoreProvider.YouCan,
  redirectRoute: "",
  howToConnectVideoUrl:
    "https://www.youtube.com/embed/lFC15nJs_-A?si=3DEubwXnzXrxzRwJ",
  supportedCountries: [EGY, UAE, KSA, IRQ, OMN],
};

const zidStore: Store = {
  name: "stores.zid",
  icon: "https://storage.googleapis.com/taager-public-media/learning/zid.svg",
  provider: StoreProvider.Zid,
  redirectRoute: "",
  supportedCountries: [KSA],
};

const wooCommerceStore: Store = {
  name: "stores.wooCommerce",
  icon: "https://storage.googleapis.com/taager-public-media/learning/woo-commerce.svg",
  provider: StoreProvider.WooCommerce,
  redirectRoute: "",
  supportedCountries: [EGY, UAE, KSA, IRQ, OMN],
};

export const storesList: Record<StoreProvider, Store> = {
  [StoreProvider.EasyOrders]: easyOrdersStore,
  [StoreProvider.Dukan]: dukanStore,
  [StoreProvider.YouCan]: youCanStore,
  [StoreProvider.Zid]: zidStore,
  [StoreProvider.WooCommerce]: wooCommerceStore,
};
