import { atom } from "@taager/react-atom";
import { country } from "../../data/services";

export const countryAtom = atom({
  key: "country",
  default: {
    market: country.shortCode || "sa",
  },
});

country.onChange(() => {
  countryAtom.change("market", country.shortCode);
});
