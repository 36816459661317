// Please note that we can use firebase checks in sync mode because we load it in the Root component
// Which will not execute anything until it is fully loaded
// Also, when the user is logged in/ complete signup, we reload it again and cache it.
import { country } from "@features/country/data";
import user from "user";
import { featureManager } from "../feature-manager";
import { firebaseFeatureProvider, userFeatureProvider } from "../providers";

export async function isFeatureEnabled(feature: string): Promise<boolean> {
  return featureManager.isEnabled(feature);
}

export async function isUserFeatureEnabled(feature: string): Promise<boolean> {
  return userFeatureProvider.isEnabled(feature);
}

export async function isFirebaseFeatureEnabled(
  feature: string,
): Promise<boolean> {
  return firebaseFeatureProvider.isEnabled(feature);
}

// Please use one of the following utils as we won't need any async checks
export function isFirebaseFeatureEnabledSync(feature: string): boolean {
  const value = firebaseFeatureProvider.getValueSync(feature);

  if (typeof value === "boolean") return value;

  if (typeof value === "object" && value !== null) {
    return parseFirebaseFeatureAssignedValueSync(value);
  }

  return false;
}

export function getFirebaseFeatureValueSync<T>(feature: string): T {
  return firebaseFeatureProvider.getValueSync<T>(feature);
}

type FeatureValueOptions = {
  status?: boolean | "even" | "odd";
  taagerIds?: number[];
  country?: string[];
};

function parseFirebaseFeatureAssignedValueSync(
  value: FeatureValueOptions,
): boolean {
  if (!value) return false;

  // We have 4 values for the status:
  // false: it mean the feature is disabled for everyone, then skip the other checks.
  // true: it mean the feature is enabled for everyone, go to the next check.
  // "even": it mean the feature is enabled for even taager ids, skip if the user id is odd.
  // "odd": it mean the feature is enabled for odd taager ids, skip if the user id is even.
  if (value.status === false) {
    return false;
  }

  // If Status is even, then we need to allow the feature for 50% of the users
  if (value.status === "even" && user.id % 2 !== 0) {
    return false;
  }

  // If Status is odd, then we need to allow the feature for 50% of the users
  if (value.status === "odd" && user.id % 2 === 0) {
    return false;
  }

  // If Country is provided, then we need to allow the feature for the users in the provided countries
  if (value.country?.length && !value.country.includes(country.code)) {
    return false;
  }

  // If Taager Ids are provided, then we need to allow the feature for the users with the provided taager ids
  if (value.taagerIds?.length && !value.taagerIds.includes(user.id)) {
    return false;
  }

  // If all checks passed, then the feature is enabled
  return true;
}
