import { captureError } from "@features/activities/data";
import { trans } from "@taager/localization";

/**
 * This function will do its best to parse the error message coming from an API
 */
export function parseError(error: any): string {
  if (error?.response) {
    // the zero status is returned because of server rate limit block as they don't send a proper response header/payload (429)
    if (!error.response?.status) {
      return trans("rateLimitError");
    }

    if (error.response?.status === 500) {
      const requestInfo = {
        url: error.response.config?.url,
        method: error.response.config?.method,
      };
      captureError(error, { ...error.response.data, requestInfo });
    }

    // Axios response
    if (error.response?.data?.message) return error.response.data.message;

    if (error.response?.data?.msg) return error.response.data.msg;

    if (error.response?.data?.description)
      return error.response.data.description;

    if (error.response?.data?.error) return error.response.data.error;

    if (error.response.status === 404) {
      return trans("notFound");
    }
  }

  // Rate limit error from load balancer
  if (
    error?.message?.includes("Network Error") &&
    error?.code === "ERR_NETWORK"
  ) {
    return trans("rateLimitError");
  }

  if (error instanceof Error) {
    captureError(error);
    return error.message;
  }

  return error;
}
