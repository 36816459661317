import cache from "@taager/cache";
import {
  getCurrentLocaleCode,
  setCurrentLocaleCode,
} from "@taager/localization";
import { LOCALE_CODE_CACHE_KEY } from "../constants";

export function updateLocaleCode(localeCode: string): void {
  cache.set(LOCALE_CODE_CACHE_KEY, localeCode);

  setCurrentLocaleCode(localeCode);
}

export function getLocalizedValue(value: any): string {
  if (getCurrentLocaleCode() === "en") {
    return value.en || value?.englishName;
  }

  return value?.ar || value?.arabicName || value || "";
}

type LocaleCodes = {
  [code: string]: {
    direction: "ltr" | "rtl";
    name: string;
  };
};

export const localeCodesList: LocaleCodes = {
  en: {
    direction: "ltr",
    name: "English",
  },
  ar: {
    direction: "rtl",
    name: "العربية",
  },
  eg: {
    direction: "rtl",
    name: "العربية",
  },
  sa: {
    direction: "rtl",
    name: "العربية",
  },
  ae: {
    direction: "rtl",
    name: "العربية",
  },
  kw: {
    direction: "rtl",
    name: "العربية",
  },
  om: {
    direction: "rtl",
    name: "العربية",
  },
  iq: {
    direction: "rtl",
    name: "العربية",
  },
};
