import { mixPanelEvent } from "@features/activities/data";

export enum RedirectMobileAppEvents {
  REDIRECT_TO_MOBILE_APP = "web_login_page_redirect_to",
}

export async function trackRedirectToMobileApp(platform: "android" | "ios") {
  mixPanelEvent(RedirectMobileAppEvents.REDIRECT_TO_MOBILE_APP, {
    platform,
  });
}
