/**
 * This utility function resolves all promises in an object and returns a new object with the resolved values.
 * It's similar to `combineLatest` from rxjs.
 *
 * @example
 * ```ts
 * const getUsers = async () => {
 *  return {
 *    id: 1
 *  }
 * };
 *
 * const getStore = async () => {
 *  return {
 *    id: 1
 *  }
 * };
 *
 * const { user, store } = await promiseAllObject({
 *  user: getUsers(),
 *  store: getStore(),
 * });
 * console.log({ user, store }); // { user: { id: 1 }, store: { id: 1 } }
 * ```
 */
export async function promiseAllObject<T extends Record<string, Promise<any>>>(
  object: T,
): Promise<{ [K in keyof T]: Awaited<T[K]> }> {
  const entries = Object.entries(object);

  const resolvedEntries = await Promise.all(
    entries.map(async ([key, promise]) => {
      const value = await promise;
      return [key, value];
    }),
  );

  return Object.fromEntries(resolvedEntries);
}
