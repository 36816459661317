import { groupedTranslations } from "@taager/localization";
import "./validation";

// Please update
groupedTranslations({
  somethingWentWrong: {
    en: "Something went wrong, please try again",
    ar: "حدث خطأ ما ، يرجى المحاولة مرة أخرى",
  },
  downloadApp: {
    en: "Download Taager App",
    ar: "حمل تطبيق تاجر",
  },
  email: {
    en: "Email",
    ar: "البريد الالكتروني",
  },
  password: {
    en: "Password",
    ar: "كلمة المرور",
  },
  send: {
    en: "Send",
    ar: "إرسال",
  },
  success: {
    en: "Success",
    ar: "تم بنجاح",
  },
  error: {
    en: "Error",
    ar: "خطأ",
  },
  info: {
    en: "Info",
    ar: "معلومة",
  },
});
