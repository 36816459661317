/**
 * This service class is responsible for managing current country in the project
 * P.S: it is not related to phone number country list in the login/registration process
 */
import { getCountryFlag } from "@features/country/data/utils";
import { mappedCountryCodes } from "@features/country/data/utils/country-codes-list";
import cache from "@taager/cache";
import events, { EventSubscription } from "@taager/events";
import { trans } from "@taager/localization";
import { Country, CountryCode, StandardCountryCode } from "../../domain";
import { CURRENT_COUNTRY_CACHE_KEY } from "../constants";

export class CountryService {
  /**
   * Current country
   */
  protected currentCountry?: Country;

  /**
   * Default market code
   * This is the one that is being added to the url
   */
  public defaultMarket = "sa";

  /**
   * Constructor
   */
  public constructor() {
    this.currentCountry = cache.get(CURRENT_COUNTRY_CACHE_KEY);
  }

  /**
   * Check if country is set
   */
  public get exists(): boolean {
    return !!this.currentCountry;
  }

  /**
   * Set default country
   * Currently it is KSA
   */
  public setDefaultCountry(): void {
    this.set({
      currencyCode: "SAR",
      isoCode2: "SA",
      isoCode3: "SAU",
      flag: getCountryFlag("SAU"),
      vat: 15,
    });
  }

  /**
   * Set current country
   */
  public set(country: Country): void {
    this.currentCountry = country;
    cache.set(CURRENT_COUNTRY_CACHE_KEY, country);

    events.trigger("country.changed", country);
  }

  /**
   * Listen to country change
   */
  public onChange(callback: (country: Country) => void): EventSubscription {
    return events.subscribe("country.changed", callback);
  }

  /**
   * Get current country code
   * Country code is represented by iso3 code
   */
  public get code(): CountryCode {
    return (this.currentCountry?.isoCode3 || "") as CountryCode;
  }

  /**
   * Get current country code shortened
   * Country shortened code is represented by iso2 code
   */
  public get shortCode(): string {
    return this.currentCountry?.isoCode2?.toLocaleLowerCase() || "";
  }

  /**
   * Get country name
   */
  public get name(): string {
    return trans(this.code);
  }

  /**
   * Determine if current country is the given country code
   */
  public is(code: StandardCountryCode): boolean {
    const properCode = mappedCountryCodes[code];
    return this.code === properCode;
  }

  public get isKSA(): boolean {
    return this.is("KSA");
  }

  public get data(): Country | undefined {
    return this.currentCountry;
  }
}

export const country = new CountryService();
